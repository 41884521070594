<template>
    <v-container fluid>
        <v-layout wrap> 
            <v-flex fluid>
                <v-card :loading="loading" outlined style="border: none;">
                    <v-card-title class="px-3 d-flex align-center justify-center">
                        <p class="blod_color" >
                            Meus Dados Pessoais
                        </p>
                    </v-card-title>
                    <v-card >
                        <v-col >
                            <!-- <pre>{{ usuario }}</pre> -->
                            <!-- <pre>{{ endereco }}</pre> -->
                            <v-row >
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.cnpj ? 'Contrato Pessoa Jurídica' : 'Contrato Pessoa Física'" readonly :label="'Contrato PF ou PJ:' "></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.apelido ? usuario.apelido: ''" readonly label="Nome"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.first_name + usuario.last_name ? usuario.first_name + usuario.last_name: ''" readonly label="Nome Completo"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.nascimento  | formatData" readonly label="Data de Nascimento"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.rg ? usuario.rg : ''" readonly label="RG"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.org_exped ? usuario.org_exped.nome: ''" readonly label="Orgão Expedidor"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="formatCPF(usuario.cpf)" readonly label="CPF" ></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="formatPhone(usuario.tel)" readonly label="Telefone" ></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.email" readonly label="E-mail"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.especialidade ? usuario.especialidade.nome : ''" readonly label="Especialidade"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.conselho ? usuario.conselho.nome : ''" readonly label="Conselho"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.uf_inscricao_conselho ? usuario.uf_inscricao_conselho.sigla : ''" readonly label="UF do Conselho"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.nconselho" readonly label="Nº Conselho"></v-textarea>
                                <v-card-title class="px-3 d-flex align-center justify-center" style="width: 100%" >
                                    <p class="blod_color">
                                        Endereço
                                    </p>
                                </v-card-title>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.endereco ? usuario.endereco.tipo.nome.trim(): ''" readonly label="Tipo"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.endereco ? usuario.endereco.logradouro: ''" readonly label="Logradouro"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.endereco ? usuario.endereco.nr: ''" readonly label="Nrº"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.endereco ? usuario.endereco.bairro: ''" readonly label="Bairro"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.endereco ? usuario.endereco.complemento: ''" readonly label="Complemento"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.endereco ? usuario.endereco.cep: ''" readonly label="CEP"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.endereco ? usuario.endereco.localidade.localidade.trim(): ''" readonly label="Município"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.endereco ? usuario.endereco.localidade.uf.sigla.trim(): ''" readonly label="UF"></v-textarea>
                                <v-card-title class="px-3 d-flex align-center justify-center" style="width: 100%" >
                                    <p class="blod_color">
                                        Dados Bancários
                                    </p>
                                </v-card-title>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.dados_bancarios ? usuario.dados_bancarios.banco.Nome_Extenso.trim() : ''" readonly label="Banco"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.dados_bancarios ? usuario.dados_bancarios.agencia : ''" readonly label="Agência"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.dados_bancarios && usuario.dados_bancarios.cc ? 'Corrente' : 'Poupança'" readonly :label="`Conta Corrente ou Poupança ${usuario.dados_bancarios && usuario.dados_bancarios.cp ? '' : ''}`"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.dados_bancarios ? usuario.dados_bancarios.conta : ''" readonly label="Conta"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.dados_bancarios ? usuario.dados_bancarios.digito : ''" readonly label="Dígito"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.dados_bancarios ? usuario.dados_bancarios.pix : ''" readonly label="PIX"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.dados_bancarios && usuario.dados_bancarios.pfj ? 'Jurídica' : 'Física'" readonly :label="`Conta Pessoa Física ou Jurídica ${usuario.dados_bancarios && usuario.dados_bancarios.pjf ? '' : ''}`"></v-textarea>
                                <v-textarea class="mx-1" rows="1" auto-grow outlined dense :value="usuario.dados_bancarios && usuario.dados_bancarios.preferencialmente ? 'PIX' : 'Conta Bancária'" readonly :label="`Preferência de Recebimento ${usuario.dados_bancarios && usuario.dados_bancarios.preferencialmente ? '' : ''}`"></v-textarea>                           
                            </v-row>
                        </v-col>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import '../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

export default {
    name: "dadospessoais_equipe_multi",
    created() {
        window.scroll(0,0);
    },
    data:() => withCRUDUtils({
        sessao:JSON.parse(localStorage.getItem('cfgu')),
        temp:[],
        usuario:{},
        loading:false,
    }),
    methods: {
        async getUsuario(){
            this.loading = true;
            const { data: permissao } = await api.get(`contas/permissoes/`)
            const { data } = await api.get(`contas/usuario/${permissao[0].id}/`)
            this.usuario = data
            this.loading = false;
        },
        formatCPF(val) {
        if (val) {
          var formattedCPF = val.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
          );
          return formattedCPF;
        }
        return "";
      },

      formatPhone(val) {
        if (val) {
          var formattedPhone = val.replace(
            /(\d{2})(\d{5})(\d{4})/,
            "($1) $2-$3"
          );
          return formattedPhone;
        }
        return "";
      },
    },
    mounted() {
        // console.log('oia eu aqui >>>',localStorage.getItem("token_usuario"))
        this.getUsuario();
    }
}
</script>